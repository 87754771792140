import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
// import { Link } from '@reach/router';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import '../css/_swiper-edits.scss';
import RightArrow from '../images/ico-right-black.svg';
import SwipeIcon from '../images/ico-swipe.svg';

SwiperCore.use([Navigation]);

export default function ProductSwiper() {
  const data = useStaticQuery(graphql`
    {
      allSanityProduct(sort: { fields: order }) {
        nodes {
          title
          category
          mainImage {
            alt
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  const allProducts = data.allSanityProduct.nodes;

  return (
    <>
      <section className="products-section">
        <div className="wrapper">
          <Swiper
            id="product-swiper"
            spaceBetween={20}
            slidesPerView="auto"
            grabCursor
            navigation={{
              prevEl: '.prev-product',
              nextEl: '.next-product',
            }}
          >
            <div className="heading" slot="container-start">
              <h2>Products</h2>
              <div className="arrows">
                <div className="prev-product">
                  <img src={RightArrow} alt="prev" />
                </div>
                <div className="next-product">
                  <img src={RightArrow} alt="next" />
                </div>
                <img src={SwipeIcon} alt="swipe" className="swipe-icon" />
              </div>
            </div>

            {allProducts.map((product, i) => (
              <SwiperSlide className="product" key={i}>
                <GatsbyImage
                  image={product.mainImage.asset.gatsbyImageData}
                  alt={product.mainImage.alt}
                  className="thumb"
                />
                <h4 className="title">
                  <span className="underline">{product.title}</span>
                </h4>
                <p>{product.category}</p>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  );
}
